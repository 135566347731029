import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link, graphql } from "gatsby"

export default ({ data }) => {
  const { edges, totalCount } = data.allMarkdownRemark
  const obituariesHeader = `${totalCount} obituar${
    totalCount === 1 ? "y" : "ies"
  }`

  return (
    <Layout>
      <SEO title="Obituaries" />

      <h1>Obituaries</h1>

      <h2>{obituariesHeader}</h2>

      {/* <div className="p-2">
				<label htmlFor="obituary-search">Name: </label>
				<input type="text" id="obituary-search" className="border border-purple-900 rounded p-2" placeholder="Type a name to filter..." />
			</div> */}

      <ul className="list-none">
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { date_of_death, name } = node.frontmatter

          return (
            <li key={slug}>
              <Link
                to={slug}
                className="block border border-purple-600 bg-purple-700 text-white mr-2 mb-2 p-4 no-underline rounded-lg hover:shadow-lg focus:shadow-lg"
              >
                <h2>{name}</h2>
                <h3>
                  <b>Date of Death</b>: {date_of_death}
                </h3>
              </Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: {
        fields: [frontmatter___date_of_death, frontmatter___name]
        order: [DESC, ASC]
      }
      filter: { fileAbsolutePath: { regex: "/.*cms/obituaries.*/" } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            date_of_death(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
